import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ISBN from 'isbn3';
import 'date-fns';
import { FaAmazon } from "react-icons/fa";
//MUI
import Link from '@material-ui/core/Link';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Divider from '@material-ui/core/Divider';
import MuiAlert from '@material-ui/lab/Alert';
import PersonIcon from '@material-ui/icons/Person';

const useStyles = makeStyles((theme) => ({

  notSelected: {
    '&:hover': {
      border: '2px solid #4169e1',
      transform: 'scale(1.2)',
      opacity: '0.9',
      transition: 'transfomr 6s cubic-bezier(0.25,0.45,0.45,0.95)',
    },
  },
  root: {
    display: 'flex',
    border: 'none',
    boxShadow: 'none',
    borderRadius: 0,
  },
  amazonButton:{
    textTransform: 'none',
    [theme.breakpoints.up('sm')]: {
      maxWidth: '200px', 
      minWidth: '200px',
      //margin: theme.spacing(2),
    },
    //backgroundColor: '#FF9900',
    //borderColor: '#FF9900',
    //color:'white',
    margin: theme.spacing(0),
  },
  rakutenButton:{
    textTransform: 'none',
    [theme.breakpoints.up('sm')]: {
      maxWidth: '200px', 
      minWidth: '200px',
      margin: theme.spacing(2),
    },
    //backgroundColor: '#bf0000',
    //color:'white',
    margin: theme.spacing(1),
  },
  details: {
    display: 'flex',
    flexDirection: 'column',
  },
  content: {
    flex: '1 0 auto',
    maxWidth: 300,
    [theme.breakpoints.down('sm')]: {
      maxWidth: 230, //160,
    },
    paddingTop: theme.spacing(1),
    '&:last-child': {
      paddingBottom: 0,
    },
  },
  cover: {
    minWidth: 128,
    minHeight: 188,
  },
  mobilePaper: {
    fontSize: 18,
    [theme.breakpoints.down('sm')]: {
      fontSize: 14,
    },
  },
  mobilePaperAuthor: {
    fontSize: '0.8rem',
  },
  logo: {
    height: 50,
    width: 60,
  },
  categoryContent: {
    flex: '1 0 auto',
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1, 0, 1, 0),
    },
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 220,
    [theme.breakpoints.down('sm')]: {
      minWidth: 150,
    },
  },
  categorySet: {
    display: 'flex',
  },
  category: {
    padding: 10,
  },
  snackBar: {
    [theme.breakpoints.down('xs')]: {
      marginBottom: theme.spacing(6),
    },
  },
  button: {
    margin: theme.spacing('auto', 1, 1, 'auto'),
  },
  dialogContent: {
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1),
    },
  },
  right: {
    justifyContent: 'flex-end',
    display: 'flex',
    padding: theme.spacing(1),
  },
  mobileButton: {
    margin: theme.spacing(0, 0, 0, 1),
  },
  paddingTitle: {
    padding: theme.spacing(2),
  },
  paddingZero: {
    padding: theme.spacing(0),
  },
  count:{
    marginLeft: theme.spacing(20),
    [theme.breakpoints.down('sm')]: {
      //marginRight: theme.spacing(1),
      marginLeft: theme.spacing(0),
    },
  },
  divider: {
    margin: theme.spacing(1.5, 0),
  },
  author: {
    fontSize: '1rem',
  },
  publisherName: {
    display: 'inline',
    fontSize: '0.7rem',
  },
  textLink: {
    textTransform: 'none',
    color: '#FF9900',
    marginTop: theme.spacing(1),
  },
  mobileGrid:{
    [theme.breakpoints.down('sm')]: {
      //marginRight: theme.spacing(1),
      marginTop: theme.spacing(1),
    },
  },
  linkSpace:{
    //marginRight: theme.spacing(1),
  }
}));

// function Alert(props) {
//   return <MuiAlert elevation={6} variant="filled" {...props} />;
// }

const imgStyle = {
  transition: 'transform .135s cubic-bezier(0.0,0.0,0.2,1),opacity linear .15s',
};

const cont = {
  backgroundColor: '#eee',
  cursor: 'pointer',
  overflow: 'hidden',
  position: 'relative',
};

//const delimiters = [KeyCodes.comma, KeyCodes.enter];

export const SelectedImage = ({
  photo,
  margin,
  direction,
  top,
  left,
  selected,
}) => {

  const theme = useTheme();
  const classes = useStyles();
  const [isSelected, setIsSelected] = useState(selected);
  //const [pickedListId, setPickedListId] = useState('');
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));


  if (direction === 'column') {
    cont.position = 'absolute';
    cont.left = left;
    cont.top = top;
  }
  const goAuthor = (event, name) => {
    event.preventDefault();
    window.location.href = 
      `/lists?type_id=1&keyword=${name}`
      ;
  }
  const goAmazon = (apiId) => {
    let isbn10 = ISBN.asIsbn10(apiId)
    if (apiId) {
        let itemurl = `https://www.amazon.co.jp/dp/${isbn10}/ref=nosim?tag=${process.env.REACT_APP_AMAZON_ASSOCIATE_ID}`;
        window.open(itemurl, '_blank');
      }
  
  };

   const goRakuten = (affiliateurl) => {
    if (affiliateurl) {
      window.open(affiliateurl, '_blank');
    } 
  };

  
  const handleOnClick = (e) => {
    setIsSelected(true);
  };

  const handleClose = (e) => {
    setIsSelected(false);
  };

  
  return (
    <div
      style={{ margin, height: photo.height, width: photo.width, ...cont }}
      className={'not-selected'}
    >
      <img
        alt="books movies tv series"
        style={{ ...imgStyle }}
        {...photo}
        onClick={handleOnClick}
      />
      <Dialog
        //disableBackdropClick
        disableEscapeKeyDown
        open={isSelected}
        onClose={handleClose}
        //fullScreen={fullScreen}
        fullWidth={true}
        maxWidth="sm"
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title" disableTypography={true}>
          {photo.title}
        </DialogTitle>

        <Divider />
        <DialogContent className={classes.dialogContent}>
          <Card className={classes.root}>
            <CardMedia
              className={classes.cover}
              image={photo.src}
              title="cover image"
            />

            <div className={classes.details}>
              <CardContent className={classes.content}>
                <Typography
                  className={classes.mobilePaper}
                  component="h5"
                  variant="h5"
                >
                  {photo.title}
                </Typography>

                <Typography
                  className={classes.mobilePaperAuthor}
                  variant="h6"
                  color="textSecondary"
                >
                  {photo.author.includes("/") ?
                  <>
                  {photo.author.split("/")
                  .filter(x => x)
                  .map((x, index) => {
                    return <Link key={index}
                    className={classes.linkSpace}
                      onClick={(event) => {
                        goAuthor(event, x)
                      }}
                      >
                        {`${x} `}
                      </Link>
                  })}
                  </>
                  :
                  <>
                  <Link
                  onClick={(event) => {
                    goAuthor(event, photo.author)
                  }}

                  >
                    {photo.author}
                  </Link>
                  </>}
                </Typography>
                <Typography
                  variant="caption"
                  color="textSecondary"
                  className={classes.publisherName}
                >
                  {photo.publishername}
                </Typography>
              
              </CardContent>
            </div>
          </Card>

          <Card className={classes.root}>
            <CardContent className={classes.categoryContent}>
            <Divider />
            {!isMobile && 
            (<Grid
              container
              direction="row"
              justifyContent="space-around"
              alignItems="center"
              >
             <Button
              onClick={() => goAmazon(photo.isbn13)}
              variant="outlined"
              className={classes.amazonButton}
              startIcon={<FaAmazon />}
            >
              Amazon で探す
            </Button>
            <Button
              
              onClick={() => goRakuten(photo.affiliateurl)}
              variant="outlined"
              className={classes.rakutenButton}
              //startIcon={<FaAmazon />}
            >
              楽天市場で探す
            </Button>
            <PersonIcon color={'primary'}/>
            {photo.count}
            </Grid>)}
            {isMobile && (
              <>
              <Grid
                  container
                  direction="row"
                  justifyContent="space-around"
                  alignItems="center"
                  className={classes.mobileGrid}
                  >
                <Button
                  onClick={() => goAmazon(photo.isbn13)}
                  variant="outlined"
                  className={classes.amazonButton}
                  startIcon={<FaAmazon />}
                >
                  Amazon
                </Button>
               
                <Button
                  onClick={() => goRakuten(photo.affiliateurl)}
                  variant="outlined"
                  className={classes.rakutenButton}
                >
                  楽天市場
                </Button>
                <PersonIcon color={'primary'} className={classes.count}/>
                    <div>{photo.count}</div>
                </Grid>
            
            </>)}


            </CardContent>

          </Card>
            <div className={classes.right}>
              <Button
                //className={classes.mobileButton}
                variant="outlined"
                color="default"
                onClick={() => setIsSelected(!isSelected)}
              >
                閉じる
              </Button>
             
            </div>
          
        </DialogContent>
        
      </Dialog>
      
      <style>{`.not-selected:hover{outline:2px solid royalblue}`}</style>
    </div>
  );
};
SelectedImage.propTypes = {
  photo: PropTypes.object,
  margin: PropTypes.string,
  direction: PropTypes.string,
  top: PropTypes.number,
  left: PropTypes.number,
  selected: PropTypes.bool,
  page: PropTypes.string,
  classes: PropTypes.object,
};

export default SelectedImage;
