import React from 'react';
//MUI
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
// const useStyles = makeStyles({
//   root: {
//     width: '100%',
//     maxWidth: 500,
//     margin: the
//   },
// });
const useStyles = makeStyles((theme) => ({
  root: {
    '& > * + *': {
      marginTop: theme.spacing(2),
      padding: '0 30px',
    },
    margin: theme.spacing(1),
    float: 'center',
  },
  margin: {
    margin: theme.spacing(5, 0, 0, 1),
  },
}));
export const Privacy = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Typography className={classes.margin} variant="h4" gutterBottom>
        プライバシーポリシー
      </Typography>
      <Typography variant="h6" gutterBottom>
        個人情報の利用目的
      </Typography>
      <Typography variant="body1" gutterBottom>
        当サイトでは、お問い合わせをする際に、名前（ハンドルネーム）等の個人情報をご登録いただく場合がございます。
        これらの個人情報は質問に対する回答や必要な情報を電子メールなどでご連絡する場合に利用させていただくものであり、個人情報をご提供いただく際の目的以外では利用いたしません。
      </Typography>
      <Typography variant="h6" gutterBottom>
        個人情報の第三者への開示
      </Typography>
      <Typography variant="body1" gutterBottom>
        当サイトでは、個人情報は適切に管理し、以下に該当する場合を除いて第三者に開示することはありません。
        ・本人のご了解がある場合 ・法令等への協力のため、開示が必要となる場合
       
        ご本人からの個人データの開示、訂正、追加、削除、利用停止のご希望の場合には、ご本人であることを確認させていただいた上、速やかに対応させていただきます。
      </Typography>
      <Typography variant="h6" gutterBottom>
        広告の配信について
      </Typography>
      <Typography variant="body1" gutterBottom>
        当サイトは第三者配信の広告サービス「Google Adsense
        グーグルアドセンス」を利用しています。
        広告配信事業者は、ユーザーの興味に応じた広告を表示するためにCookie（クッキー）を使用することがあります。
        Cookie（クッキー）を無効にする設定およびGoogleアドセンスに関する詳細は「広告
        – ポリシーと規約 – Google」をご覧ください。
        また、当サイトは、Amazon.co.jpを宣伝しリンクすることによってサイトが紹介料を獲得できる手段を提供することを目的に設定されたアフィリエイトプログラムである、Amazonアソシエイト・プログラムの参加者です。
        第三者がコンテンツおよび宣伝を提供し訪問者から直接情報を収集し訪問者のブラウザにCookie（クッキー）を設定したりこれを認識したりする場合があります。
      </Typography>
      <Typography variant="h6" gutterBottom>
        アクセス解析ツールについて
      </Typography>
      <Typography variant="body1" gutterBottom>
        当サイトでは、Googleによるアクセス解析ツール「Googleアナリティクス」を利用しています。このGoogleアナリティクスはトラフィックデータの収集のためにCookieを使用しています。
        このトラフィックデータは匿名で収集されており個人を特定するものではありません。この機能はCookieを無効にすることで収集を拒否することが出来ますので、お使いのブラウザの設定をご確認ください。
      </Typography>
      <Typography variant="h6" gutterBottom>
        免責事項
      </Typography>
      <Typography variant="body1" gutterBottom>
        当サイトからリンクやバナーなどによって他のサイトに移動された場合、移動先サイトで提供される情報、サービス等について一切の責任を負いません。
        当サイトのコンテンツ・情報につきまして、可能な限り正確な情報を掲載するよう努めておりますが、誤情報が入り込んだり情報が古くなっていることもございます。
        当サイトに掲載された内容によって生じた損害等の一切の責任を負いかねますのでご了承ください。
      </Typography>
      <Typography variant="h6" gutterBottom>
        プライバシーポリシーの変更について
      </Typography>
      <Typography variant="body1" gutterBottom>
        当サイトは、個人情報に関して適用される日本の法令を遵守するとともに、本ポリシーの内容を適宜見直しその改善に努めます。
        修正された最新のプライバシーポリシーは常に本ページにて開示されます。
      </Typography>
    </div>
  );
};

export default Privacy;
