import React from 'react';
import Typography from '@material-ui/core/Typography';
import { Tweet } from 'react-twitter-widgets'
//MUI
import Link from '@material-ui/core/Link';
import Box from '@material-ui/core/Box';
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import { useHistory } from 'react-router';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

const useStyles = makeStyles((theme) => ({
  marginTopTitle: {
    margin: theme.spacing(10, 1, 0, 1),
  },
  marginTop: {
    margin: theme.spacing(5, 0, 0, 0),
  },
  text: {
    textAlign: 'center',
    width: '100%',
  },
  textTwitter:{
    textAlign: 'center',
    width: '100%',
    margin: theme.spacing(2, 0, 3, 0),
  },
  textOr: {
    textAlign: 'center',
    width: '100%',
    margin: theme.spacing(0.5),
  },
  marginTopText: {
    textAlign: 'center',
    width: '100%',
    margin: theme.spacing(5, 0, 0, 0),
  },
  textLogin: {
    textAlign: 'center',
    width: '100%',
    margin: theme.spacing(4, 0, 0, 0),
  },
  button: {
    margin: theme.spacing(2),
    textTransform: 'none',
  },
  twitterEmbed:{
    //textAlign: 'center',
    margin: theme.spacing(2),
  }
  // root: {
  //   width: '100%',
  //   maxWidth: 500,
  // },
  // divider: {
  //   margin: theme.spacing(5, 0),
  // },
}));

// function Copyright(classes) {
//   return (
//     // <Typography variant="body2" color="text.secondary">
//     //   {'Copyright © '}
//     //   <Link color="inherit" href="'https://toptennovels.com/">
//     //     名刺代わりの小説10選集計ツール
//     //   </Link>{' '}
//     //   {new Date().getFullYear()}
//     //   {'.'}
//     // </Typography>
    

//   );
// }
// function StickyFooter() {
//   return (
//     <Box
//       sx={{
//         display: 'flex',
//         flexDirection: 'column',
//         minHeight: '100vh',
//       }}
//     >
//       <CssBaseline />
//       <Container component="main" sx={{ mt: 8, mb: 2 }} maxWidth="sm">
        
//       </Container>
//       <Box
//         component="footer"
//         sx={{
//           py: 3,
//           px: 2,
//           mt: 'auto',
//           backgroundColor: (theme) =>
//             theme.palette.mode === 'light'
//               ? theme.palette.grey[200]
//               : theme.palette.grey[800],
//         }}
//       >
//         <Container maxWidth="sm">
//           {/* <Typography variant="body1">
//             My sticky footer can be found here.
//           </Typography> */}
//           <Copyright />
//         </Container>
//       </Box>
//     </Box>
//   );
// }
const HomePage = () => {
  const classes = useStyles();
  const history = useHistory();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <div className="homepage">
       <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
      }}
    >
      <CssBaseline />
      <Container component="main" sx={{ mt: 8, mb: 2 }}>
      {isMobile ?
        (<><Typography className={classes.marginTopText} variant="h5" gutterBottom>
          名刺代わりの小説10選
        </Typography>
        <Typography className={classes.text} variant="h5" gutterBottom>
            集計ツール
        </Typography></>)
      :
      (<><Typography className={classes.marginTopText} variant="h3" gutterBottom>
          名刺代わりの小説10選
      </Typography>
      <Typography className={classes.text} variant="h3" gutterBottom>
          集計ツール
      </Typography></>)}
      <Typography className={classes.text} variant="subtitle1" gutterBottom>
        ツイートを集計し、データをランキング形式で表示するウェブアプリ
      </Typography>
      <Typography
        className={classes.textTwitter}
        variant="subtitle2"
        gutterBottom
      >
            集計方法については <Link 
              target="_blank"
              href="https://poland-it-blog.com/aggregate-method/">こちら</Link>
      </Typography>
      {/* <Typography className={classes.text} variant="subtitle1" gutterBottom>
          人気小説をランキング形式で表示するウェブアプリ
      </Typography> */}

      <Tweet 
        tweetId="1454962476961959944"
        options={{ align: "center"}} />
      
       
      </Container>
      <Box
        component="footer"
        sx={{
          py: 3,
          px: 2,
          mt: 'auto',
          backgroundColor: (theme) =>
            theme.palette.mode === 'light'
              ? theme.palette.grey[200]
              : theme.palette.grey[800],
        }}
      >
        <Container >
          {/* <Typography variant="body1">
            My sticky footer can be found here.
          </Typography> */}
            <Grid
            container
            direction="row"
            justifyContent="space-evenly"
            alignItems="flex-end"
            className={classes.divider}
          >
          <Typography style={{ width: 200 }} variant="body1" gutterBottom>
              <Link
                color="initial"
                component="button"
                variant="body2"
                onClick={() => {
                  history.push("/")
                }}
              >
                ホームページ
              </Link>
            </Typography>
            <Typography style={{ width: 200 }} variant="body1" gutterBottom>
              <Link
                color="initial"
                component="button"
                variant="body2"
                onClick={() => {
                  window.open(
                    'https://toptennovels.com/term',
                    '_blank'
                  );
                }}
              >
                利用規約
              </Link>
            </Typography>
            <Typography style={{ width: 200 }} variant="body1" gutterBottom>
              <Link
                color="initial"
                component="button"
                variant="body2"
                onClick={() => {
                  window.open(
                    'https://toptennovels.com/privacy',
                    '_blank'
                  );
                }}
              >
                プライバシーポリシー
              </Link>
            </Typography>
            
            <Typography style={{ width: 200 }} variant="body1" gutterBottom>
              <Link
                color="initial"
                component="button"
                variant="body2"
                onClick={() => {
                  window.open('https://twitter.com/top10novels', '_blank');
                }}
              >
                Twitter アカウント
              </Link>
            </Typography>
          </Grid>
        </Container>
      </Box>
    </Box>
    </div>
  );
};

export default HomePage;
