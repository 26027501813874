import React, { useEffect, useState, useCallback } from 'react';
import Gallery from 'react-photo-gallery';
import DynamicLoading from './dynamic.loading';
import { TabPanel } from './tab.panel.component';
import algoliasearch from 'algoliasearch';
import { firestore } from '../../firebase/firebase.utils';
import SelectedImage from './selectedImage.component';
import IndividualListItem from './individual.list.item.component';
//MUI
import Typography from '@material-ui/core/Typography';
import Snackbar from '@material-ui/core/Snackbar';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Divider from '@material-ui/core/Divider';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';
import Paper from '@material-ui/core/Paper';
import Pagination from '@material-ui/lab/Pagination';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import MuiAlert from '@material-ui/lab/Alert';
//MUI Icon
import SearchIcon from '@material-ui/icons/Search';
import SwapVertIcon from '@material-ui/icons/SwapVert';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    margin: theme.spacing(0.5, 'auto'),
    padding: theme.spacing(0, 1),
    color: 'white',
    [theme.breakpoints.up('sm')]: {
      width: 650,
    },
    '& .MuiTabs-flexContainer': {
      background: '#fff',
    },
  },
  listItem:{
    [theme.breakpoints.up('sm')]: {
        marginTop: theme.spacing(5),
    },
    marginTop: theme.spacing(3),
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  title: {
    color: '#2c2c2c',
    margin: theme.spacing(3, 'auto', 2.5, 'auto'),
  },
  tabRoot: {
    flexGrow: 1,
    color: 'white',
  },
  rootButton: {
    '& > *': {
      margin: theme.spacing(1, 1, 0, 0),
    },
  },
  filterTab: {
    margin: theme.spacing(-2, 0, 2, 'auto'),
  },
  paper: {
    marginRight: theme.spacing(2),
    width: 300,
    [theme.breakpoints.up('sm')]: {
      width: 400,
    },
    color: 'white',
  },
  categoryPaper: {
    [theme.breakpoints.down('sm')]: {
      width: 270,
    },
    [theme.breakpoints.up('sm')]: {
      width: 400,
    },
  },
  pagination: {
    margin: theme.spacing(1),
  },
  snackBar: {
    [theme.breakpoints.down('xs')]: {
      marginBottom: theme.spacing(6),
    },
  },
  typography:{
    textColor: 'black',
    color: 'black',
    margin: theme.spacing(1, 2, 1, 0),
  },
}));
  
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }
  
export const Main = (props) => {
    
    
    const imageRenderer = useCallback(
        ({ index, left, top, key, photo }) => (
          <SelectedImage
            selected={false}
            key={key}
            margin={'2px'}
            index={index}
            photo={photo}
            left={left}
            top={top}
            direction={'column'}
            type={'all'}
          />
        ),
        []
      );
    const classes = useStyles();
    const limitNum = 500;
    const search = props.location.search;
    const params = new URLSearchParams(search);
    const type_id = parseInt(params.get('type_id'));
    const sort_id = parseInt(params.get('sort_id'));
    const keyword = params.get('keyword');
    //const filter_id = parseInt(params.get('filter_id'));
    //   const keyword = params.get('keyword');
     
    const [booksImg, setBooksImg] = useState([]);
    const [topBooksImg, setTopBooksImg] = useState([])
        
    const [value, setValue] = useState(type_id);
    const [sortValue, setSortValue] = useState(sort_id)
    const [sortOpen, setSortOpen] = useState(false);
    const [searchOpen, setSearchOpen] = useState(false);
    const [dialogSearchText, setDialogSearchText] = useState('');
    const [noItem, setNoItem] = useState(false);
    
    const [searchPageIndex, setSearchPageIndex] = useState(1);
    const [searchResults, setSearchResults] = useState([]);
    const [searchImg, setSearchImg] = useState([]);
    const [searchArray, setSearchArray] = useState();
    const [uniqueBook, setUniqueBook] = useState(0);

    const searchClient = algoliasearch(
        process.env.REACT_APP_ALGOLIA_APP_ID,
        process.env.REACT_APP_ALGOLIA_ADMIN_API_KEY
      );
    const searchIndex = searchClient.initIndex('items');
    
    const handleSortChange = (event) => {
        window.location.href = 
            `/lists?type_id=1&sort_id=${event.target.value}`
        ;
    };

    const noItemsClose = () => {
        setNoItem(false)
    }
    const searchClose = () => {
        setSearchOpen(false)
        setDialogSearchText('')
    }
    const handleSearch = async (keyword) => {
        try {
          let searchArray = [];
          
          const results = await searchIndex.search(keyword);
         
          results.hits.forEach((result) => {
            searchArray.push(result.docid);
          });

          if(searchArray.length === 0){
              setNoItem(true)
          }
          setSearchResults(searchArray);
          
        } catch (error) {
          
          setSearchImg([]);
        }
      };
    
    const filterTab = () => (
        <>
          <div className={classes.filterTab}>
            <Grid container justifyContent="flex-end">
            <Typography 
                className={classes.typography}
                variant="overline" display="block" gutterBottom>
                
              </Typography>
              <Typography 
                className={classes.typography}
                variant="overline" display="block" gutterBottom>
                {keyword ? 
                '最大20件の検索結果を表示'
                :`${uniqueBook}作品中最大${limitNum}冊までを表示`
                }
              </Typography>
              <div className={classes.rootButton}>

                <Button
                  size="small"
                　disabled={!!keyword}
                　color={sort_id === 1 ? 'default' : 'primary'}
                  variant={sort_id === 1 ? 'text' : 'outlined'}
                　onClick={() => setSortOpen(true)}
                    startIcon={<SwapVertIcon />}
                  style={{textTransform: 'none'}}
                >
                  並び替え
                </Button>
                
                <Button
                  size="small"
                  //disabled={!!keyword}
                  onClick={() => setSearchOpen(true)}
                  startIcon={<SearchIcon />}
                  style={{textTransform: 'none'}}
                >
                  検索
                </Button>
                {(keyword || sort_id !== 1) && (
              <Button
                size="small"
                onClick={() => {
                  window.location.href = 
                    `/lists?type_id=1&sort_id=1`
                  ;
                }}
                color="secondary"
                variant="outlined"
              >
                リセット
              </Button>
            )}

              </div>
            </Grid>
            <Dialog
              open={sortOpen}
              onClose={() => setSortOpen(false)}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">並び替え</DialogTitle>
              <DialogContent>
              <Divider className={classes.pagination}/>
                <Paper className={classes.categoryPaper} elevation={0}>
                 
                  <FormControl component="fieldset">
                  <RadioGroup aria-label="gender" name="gender1" value={sortValue} onChange={handleSortChange}>
                  <FormControlLabel value={1} control={<Radio />} label="票数：多い順" />
                    <FormControlLabel value={2} control={<Radio />} label="票数：少ない順" />
                    <FormControlLabel value={3} control={<Radio />} label="登録日：新しい順" />
                    <FormControlLabel value={4} control={<Radio />} label="登録日：古い順" />
                    </RadioGroup>
                </FormControl>
                  <Divider className={classes.pagination}/>
                </Paper>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={() => setSortOpen(false)}
                  variant="outlined"
                  color="default"
                >
                  閉じる
                </Button>
              </DialogActions>
            </Dialog>
    

            <Dialog
              open={searchOpen}
              onClose={searchClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">{'検索'}</DialogTitle>
              <DialogContent>
              {/* <Paper className={classes.categoryPaper} elevation={0}> */}
                <DialogContentText>
                  {'棚に登録された作品をタイトル、著者名で検索することができます。'}
                </DialogContentText>
                    
              <TextField
                  autoFocus
                  margin="dense"
                  id="name"
                  type=""
                  fullWidth
                  onChange={(e) => setDialogSearchText(e.target.value)}
              />

                {/* </Paper> */}
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={searchClose}
                  variant="outlined"
                  color="default"
                >
                  閉じる
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  disabled={!dialogSearchText}
                  onClick={() => {
                    setSearchOpen(false);
                    
                    window.location.href = 
                    `/lists?type_id=1&keyword=${dialogSearchText}`
                    ;
                }}
                >
                 検索
                </Button>
              </DialogActions>
            </Dialog>
          </div>
        </>
      );
      
    
    const handleChange = (event, newValue) => {
        if(newValue){
            window.location.href = 
            `/lists?type_id=1&sort_id=1`
            ;
        }else{
            window.location.href = 
            `/lists?type_id=0`
            ;
        }
        
    };
    

    useEffect(() => {
        if (type_id === 0) {
          return firestore
            .collection('books')
            .orderBy('count', 'desc')
            .limit(10)
            .get()
            .then((querySnapshot) => {
              let topBooksArray = [];
    
              querySnapshot.forEach((doc) => {
                const { src, title, author, publishername } = doc.data();
                topBooksArray.push({
                  key: doc.id,
                  id: doc.id,
                  src,
                  title,
                  author,
                  width: 4,
                  height: 6,
                  publishername,
                  createdat: doc.data().createdAt,
                  affiliateurl: doc.data().affiliateurl,
                  isbn13: doc.data().isbn13,
                  count: doc.data().count
                });
              });
    
              //setTopBooksCnt(cnt);
              setTopBooksImg(topBooksArray);
              
            });
        }
      }, []);

 
      
      useEffect(() => {
        
        if (type_id === 1 && sort_id) {
            let sortType = ""
            let sortOrder = ""
            switch(sort_id){
                case 1: 
                    sortType = "count";
                    sortOrder = "desc";
                    break;
                case 2: 
                    sortType = "count";
                    sortOrder = "asc"; 
                    break;
                case 3: 
                  sortType = "createdAt";
                  sortOrder = "desc";
                    break;
                case 4:
                  sortType = "createdAt";
                  sortOrder = "asc";
                    break;
                default:
                    sortType = "createdAt";
                    sortOrder = "desc";
            }
          firestore
          .collection("unique")
          .doc("50ArjjYNh4p05SW3hJOy")
          .get()
          .then((data) => {
              setUniqueBook(data.data().uniquebooksnum)
          })
          return firestore
            .collection('books')
            .orderBy(sortType, sortOrder)
            .limit(limitNum)
            .get()
            .then((querySnapshot) => {
             
              let totalBooksArray = [];
             
              querySnapshot.forEach((doc) => {
               
                const { src, title, author, publishername } = doc.data();
                totalBooksArray.push({
                  key: doc.id,
                  id: doc.id,
                  src,
                  title,
                  author,
                  width: 4,
                  height: 6,
                  publishername,
                  createdat: doc.data().createdAt,
                  affiliateurl: doc.data().affiliateurl,
                  isbn13: doc.data().isbn13,
                  count: doc.data().count
                });
              });
    
              //setTopBooksCnt(cnt);
              setBooksImg(totalBooksArray);
              
            });
        }
      }, []);

 
      useEffect(() => {
        if (keyword) {
          handleSearch(keyword);
         
        }
      }, []);

      useEffect(() => {
        if (keyword && searchResults.length > 0 && searchResults.length < 9) {
          return firestore
            .collection('books')
            .where('docid', 'in', searchResults)
            .orderBy('title')
            .get()
            .then((querySnapshot) => {
            let resultsArray = [];
              querySnapshot.forEach((doc) => {
                const { src, title, author, publishername } = doc.data();
                resultsArray.push({
                  key: doc.id,
                  id: doc.id,
                  src,
                  title,
                  author,
                  width: 4,
                  height: 6,
                  publishername,
                  createdat: doc.data().createdAt,
                  affiliateurl: doc.data().affiliateurl,
                  isbn13: doc.data().isbn13,
                  count: doc.data().count
                });
              });
    
             
              setSearchImg(resultsArray);
              
              
            });
        } else if (keyword && searchResults.length > 8) {
          let resultsLength = searchResults.length;
          //let cnt = 0;
          let times = 0;
    
          if (resultsLength < 100) {
            times = Math.ceil((resultsLength / 8) % 8);
            let array = [];
    
            for (var i = 0; i < times; i++) {
              if (i !== times) {
                array[i] = searchResults.slice(8 * i, 8 + i * 8);
              } else {
                array[i] = searchResults.slice(8 * i, searchResults.length);
              }
            }
            //setBooksCnt(searchResults.length);
            setSearchArray(array);
          } else {
            return null;
          }
         
        }
      }, [searchResults]);

      useEffect(() => {
        if (searchArray) {
          return firestore
            .collection('books')
            .where('docid', 'in', searchArray[searchPageIndex - 1])
            .orderBy('title')
            .get()
            .then((querySnapshot) => {
                let booksArray = [];
                querySnapshot.forEach((doc) => {
                  const { src, title, author, publishername } = doc.data();
                  booksArray.push({
                    key: doc.id,
                    id: doc.id,
                    src,
                    title,
                    author,
                    width: 4,
                    height: 6,
                    publishername,
                    createdat: doc.data().createdAt,
                    affiliateurl: doc.data().affiliateurl,
                    isbn13: doc.data().isbn13,
                    count: doc.data().count
                  });
                });
    
              //setBooksCnt(searchResults.length );
    
              setSearchImg(booksArray);
              
              
            });
        }
      }, [searchArray, searchPageIndex]);
    
    
    return(
        <div className={classes.root}>
        <h2 className={classes.title}>みんなの#名刺代わりの小説10選</h2>
  
        <div className={classes.tabRoot}>
          <AppBar elevation={0} position="static" color="default">
            <Tabs
              className={classes.white}
              value={value}
              onChange={handleChange}
              variant="fullWidth"
              indicatorColor="primary"
              textColor="primary"
              aria-label="simple tabs example"
            >
              <Tab
                style={{ textTransform: 'none' }}
                label={'トップ10'}
                {...a11yProps(0)}
              />
              <Tab
                style={{ textTransform: 'none' }}
                label={'登録リスト'}
                {...a11yProps(1)}
              />
              
            </Tabs>
          </AppBar>
          <TabPanel value={value} index={0}>

            { 
               topBooksImg.length > 0 && (
                  <>
                    <DynamicLoading photos={topBooksImg} type={'topBook'} />
                  </>
                )}
            <div className={classes.listItem}>
            {
               topBooksImg.length > 0 && 
                topBooksImg.map((list, index) => (
                    <div  key={index} >
                        <IndividualListItem 
                            list={list} 
                            index={index}/>
                    </div>
                ))
               
            }
            
            </div>
          </TabPanel>
          <TabPanel value={value} index={1}>
          {filterTab()}
            {keyword 
                ?  searchImg.length > 0 && (
                <>
                  {/* <FilteredDynamicLoading photos={searchImg} type={'book'} /> */}
                  <Gallery
                    photos={searchImg}
                    direction={'column'}
                    columns={4}
                    margin={3}
                    renderImage={imageRenderer}
                  />
                  <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                  >
                    {searchArray && (
                      <Pagination
                        className={classes.pagination}
                        count={searchArray.length}
                        page={searchPageIndex}
                        onChange={(event, value) => setSearchPageIndex(value)}
                      />
                    )}
                  </Grid>
                </>
              )
               : booksImg.length > 0 && (
                  <>
                    <DynamicLoading photos={booksImg} type={'book'} />
                  </>
                )}
          </TabPanel>
          <Snackbar
            className={classes.snackBar}
            open={noItem}
            autoHideDuration={3000}
            onClose={noItemsClose}
        >
        <Alert onClose={noItemsClose} severity="warning">
          該当する作品が見つかりませんでした
        </Alert>
      </Snackbar>

      <style>{`.not-selected:hover{outline:2px solid royalblue}`}</style>

        </div>
      </div>
  
    )
}

export default Main