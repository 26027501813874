import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { ReactComponent as Logo } from '../../assets/logo.svg';
//MUI
import FormatListNumberedIcon from '@material-ui/icons/FormatListNumbered';
import AppBar from '@material-ui/core/AppBar';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import clsx from 'clsx';
import { useHistory } from 'react-router-dom';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
//MUI ICON
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';

import EqualizerIcon from '@material-ui/icons/Equalizer';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import InfoIcon from '@material-ui/icons/Info';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import EmojiEventsIcon from '@material-ui/icons/EmojiEvents';

const drawerWidth = 270;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',

    '& .MuiNativeSelect-select.MuiNativeSelect-select': {
      backgroundColor: '#fff',
    },
  },
  title: {
    margin: theme.spacing(2, 0, 1, 2),
    cursor: 'pointer',
    '&:hover': {
      cursor: 'pointer'
    },
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  },
  divider: {
    margin: theme.spacing(0, 8, 0, 1),
    backgroundColor: '#ff7a7a',
  },
  appBar: {
    backgroundColor: '#bededb',
    //backgroundColor: '#a5d3ad',
  },

  appBarShift: {
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
      backgroundColor: '#bededb', //b9e1dc
      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
  },
  menuButtonMobile: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  menuButtonWeb: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  closeButtonWeb: {
    margin: theme.spacing(1, 0, 0, 'auto'),
  },
  hide: {
    display: 'none',
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  contentShift: {
    [theme.breakpoints.up('sm')]: {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: drawerWidth,
    },
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 2),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-start',
    backgroundColor: '#bededb',
    paddingBottom: theme.spacing(1.5),
  },
  marginRight: {
    margin: theme.spacing(0, 0, 0, 'auto'),
  },
  otherButtonNoUser: {
    margin: theme.spacing(0, 0, 0, 'auto'),
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  accordionRoot: {
    width: '100%',
  },
  arrowButton: {
    margin: theme.spacing(0, 1, 0, 0),
  },
  listRoot: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  listButton: {
    color: '#d4d4d4',
    margin: theme.spacing(0, 0, 0, 'auto'),
    '&:hover': {
      color: '#2c3434',
    },
  },
  noPadding: {
    height: '100%',
    overflowY: 'scroll',
  },
  logo: {
    marginTop: theme.spacing(2),
  },
}));

function ResponsiveDrawer(props) {
  const { _window } = props;
  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();
  //const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [mobileOpen, setMobileOpen] = useState(false);
  const [open, setOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const handleMobileClose = () => {
    setMobileOpen(false);
  };
  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };


  const drawer = (
    <div className={classes.noPadding}>
      <div className={classes.drawerHeader}>
        <Link className={classes.logo} to="/">
          {<Logo
            onClick={() => {
              handleMobileClose();
              handleDrawerClose();
            }}
          />}
        </Link>
        <IconButton
          className={clsx(classes.closeButtonWeb, !open && classes.hide)}
          onClick={handleDrawerClose}
        >
          {theme.direction === 'ltr' ? (
            <ChevronLeftIcon />
          ) : (
            <ChevronRightIcon />
          )}
        </IconButton>
      </div>
      
      <Typography
       onClick = {() => {
        window.location.href = 
          `/lists?type_id=0`
        ;
       }}
        className={classes.title} variant="h6">
      名刺代わりの小説10選
      </Typography>

          <Divider />
          <div className={classes.listRoot}>
            <List>
            <ListItem
                button
                onClick={() => {
                  handleMobileClose();
                  window.location.href = 
                  '/lists?type_id=0';
                }}
              >
                <ListItemIcon>
                  <FormatListNumberedIcon />
                </ListItemIcon>
                <ListItemText primary={'トップ10'} />
              </ListItem>
              <ListItem
                button
                onClick={() => {
                  handleMobileClose();
                  window.location.href = 
                  '/lists?type_id=1&sort_id=1';
                  
                }}
              >
                <ListItemIcon>
                  <LibraryBooksIcon />
                </ListItemIcon>
                <ListItemText primary={'登録リスト'} />
              </ListItem>
              <ListItem
                button
                onClick={() => {
                  handleMobileClose();
                  history.push('/stats');
                }}
              >
                <ListItemIcon>
                  <EqualizerIcon />
                </ListItemIcon>
                <ListItemText primary={'月別データ'} />
              </ListItem>
              <ListItem
                button
                onClick={() => {
                  handleMobileClose();
                  history.push('/ranking');
                }}
              >
                <ListItemIcon>
                  <EmojiEventsIcon />
                </ListItemIcon>
                <ListItemText primary={'著者ランキング'} />
              </ListItem>
 
              <ListItem
                button
                onClick={() => {
                  handleMobileClose();
                  handleDrawerClose();
                  history.push('/about');
                }}
              >
                <ListItemIcon>
                  <InfoIcon />
                </ListItemIcon>
                <ListItemText primary={'サイトについて'} />
              </ListItem>
                        
            </List>
          </div>
    </div>
  );

  const container =
    _window !== undefined ? () => window().document.body : undefined;

  return (
    <div className={classes.root}>
      <AppBar
        elevation={0}
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar>
          {
            <>
              <IconButton
                //disabled={!currentUser}
                aria-label="open drawer"
                edge="start"
                onClick={handleDrawerToggle}
                className={classes.menuButtonMobile}
              >
                <MenuIcon />
              </IconButton>
              <IconButton
                //disabled={!currentUser}
                aria-label="open drawer"
                onClick={handleDrawerOpen}
                edge="start"
                className={clsx(classes.menuButtonWeb, open && classes.hide)}
              >
                <MenuIcon />
              </IconButton>

              
            </>
          }
        </Toolbar>
      </AppBar>

      
      <nav className={classes.drawer} aria-label="mailbox folders">
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Hidden smUp implementation="css">
          <Drawer
            container={container}
            variant="temporary"
            anchor={theme.direction === 'rtl' ? 'right' : 'left'}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            {drawer}
          </Drawer>
        </Hidden>
        {(
          <Hidden xsDown implementation="css">
            <Drawer
              className={classes.drawer}
              variant="persistent"
              anchor="left"
              open={open}
              classes={{
                paper: classes.drawerPaper,
              }}
            >
              {drawer}
            </Drawer>
          </Hidden>
        )}
      </nav>
      <main
        className={clsx(classes.content, {
          [classes.contentShift]: open,
        })}
      ></main>
    </div>
  );
}
ResponsiveDrawer.propTypes = {
  _window: PropTypes.func,
};
export default ResponsiveDrawer;
