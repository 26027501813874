import React, { useEffect, useState } from 'react';
import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
import { firestore } from '../../firebase/firebase.utils';
import { makeStyles } from '@material-ui/core/styles';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';

//MUI
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
//icon
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    margin: theme.spacing(2, 'auto'),
    padding: theme.spacing(0),
    [theme.breakpoints.up('sm')]: {
      width: 650,
    },
  },

  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  year: {
    margin: theme.spacing(1, 0),
  },
  icon: {
    margin: theme.spacing(0, 1),
    cursor: 'pointer',
  },
  disabledIcon: {
    margin: theme.spacing(0, 1),
    //color : '#ADD8E6'
  },
  paper: {
    margin: theme.spacing(1.5, 0),
    [theme.breakpoints.down('sm')]: {
      margin: theme.spacing(0.5, 0),
    },
  },
  imgPaper: {
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(0, 0, 3, 0),
    },
    padding: theme.spacing(3),
  },
  formControl: {
    //margin: theme.spacing(1),
    minWidth: 120,

    //marginRight: theme.spacing('auto'),
    [theme.breakpoints.down('sm')]: {
      minWidth: 100,
      maxWidth: 100,
    },
  },
  grid: {
    margin: theme.spacing(0),
  },
}));

export const Stats = () => {
  const classes = useStyles();
  const [displayTweetNum, setDisplayTweetNum] = useState([0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]);
  const [displayBookNum, setDisplayBookNum] = useState([0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]);
  const [defaultYear, setDefaultYear] = useState(new Date().getYear() + 1900);
  //const mode = ['累計Tweet数', '累計作品数'];

  const labels = [
    '1月',
    '2月',
    '3月',
    '4月',
    '5月',
    '6月',
    '7月',
    '8月',
    '9月',
    '10月',
    '11月',
    '12月',
  ];


  const useYears = (years) => {
    const [items, setItems] = useState({ datasets: [], labels: [] });
    const startDate = firebase.firestore.Timestamp.fromDate(
      new Date(`${years}-1-1`)
    );
    const endDate = firebase.firestore.Timestamp.fromDate(new Date(years, 12));

    useEffect(() => {
        let booksDataArray = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
        let tweetsDataArray = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
        let sumTweets = [];
        let sumBooks = [];
        let booksCnt = 0;
        let tweetsCnt = 0;
        firestore
          .collection('total')
          .where('createdAt', '>=', startDate)
          .where('createdAt', '<=', endDate)
          .orderBy('createdAt')
          .get()
          .then((snapshot) => {
            snapshot.docs.reduce((index, item) => {

              tweetsCnt += item.data().totaltweets;
              tweetsDataArray[item.data().createdAt.toDate().getMonth()] += item.data().totaltweets;
              booksCnt += item.data().totalbooks;
              booksDataArray[item.data().createdAt.toDate().getMonth()] += item.data().totalbooks;
              
              return null;
            }, []);
           
    
            tweetsDataArray.map((element, index) => {
              
              if(index === 0 || element === 0){
                sumTweets.push(element)
              }else{
                sumTweets.push(tweetsDataArray.slice(0, index + 1).reduce((a, b) => a + b, 0))
              } 
              return null          
            })
           
            //console.log("tweet", sumTweets)
            booksDataArray.map((element, index) => {
              if(index === 0 || element === 0){
                sumBooks.push(element)
              }else{
                sumBooks.push(booksDataArray.slice(0, index + 1).reduce((a, b) => a + b, 0))
              } 
              
              return null       
            })
            if(years === defaultYear){
              setDisplayTweetNum(sumTweets)
              setDisplayBookNum(sumBooks)
            }
            
            setItems({
              labels: labels,
              datasets: [
                
                { 
                  type: 'bar',
                  label: `累積ツイート数（年間）`,
                  data: sumTweets,
                  backgroundColor: 'rgba(54, 162, 235, 0.6)',
                  borderColor:'rgba(54, 162, 235, 1)',
                  borderWidth: 1,
                },
                {　
                  type: 'bar',
                  label: `累積票数（年間）`,
                  data: sumBooks,
                  backgroundColor: 'rgba(255, 99, 132, 0.6)',
                  borderColor: 'rgba(255, 99, 132, 1)',
                  borderWidth: 1,
                  //fill: false,
                },
              ],
            });
          });

    }, [defaultYear]);
    return { items, setItems };
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: true,
        position: 'bottom',
      },
      
    },
  };
  // const options = {
  //   legend: {
  //     display: true,
  //     position: 'bottom',
  //   },
  //   scales: {
  //     yAxes: [
  //       {
  //         ticks: {
  //           beginAtZero: true,
  //           //stepSize: 1,
  //         },
  //         gridLines: {
  //           display: true,
  //         },
  //       },
  //     ],
  //   },
  // };

  let { items } = useYears(defaultYear);
  
 
  return (
    <div className={classes.root}>
      <div className="chart">
        <Paper className={classes.paper}>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            className={classes.grid}
          >
            <NavigateBeforeIcon
              className={classes.icon}
              color="primary"
              onClick={() => setDefaultYear(defaultYear - 1)}
            />

            <Typography className={classes.year} variant="h5" gutterBottom>
              {defaultYear}
            </Typography>

            {defaultYear !== new Date().getFullYear() ? (
              <NavigateNextIcon
                className={classes.icon}
                color="primary"
                onClick={() => setDefaultYear(defaultYear + 1)}
              />
            ) : (
              <NavigateNextIcon
                color="primary"
                className={classes.disabledIcon}
              />
            )}
          </Grid>
          
          <Bar
            data={items}
            options={options}
            // getElementAtEvent={(e) =>
            //   e[0]
            //     ? setBarClicked({
            //         clickedType: mode[e[0]._datasetIndex],
            //         clickedMonth: e[0]._index,
            //       })
            //     : undefined
            // }
          />
        </Paper>
      </div>
      <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
            className={classes.grid}
          >
           {defaultYear === new Date().getYear() + 1900 &&
           <Typography className={classes.year} variant="h6" gutterBottom>
              { 
              `ツイート数：${835 + displayTweetNum[new Date().getMonth()]}`
              }
            </Typography>}
            {defaultYear === new Date().getYear() + 1900 &&
           <Typography className={classes.year} variant="h6" gutterBottom>
              {`票数：${5344 + displayBookNum[new Date().getMonth()]}`}
            </Typography>}
          </Grid>
    </div>
  );
};

export default Stats;
