import React from 'react';
import { Switch, Route } from 'react-router-dom';
import './App.css';
import ResponsiveDrawer from './components/responsive-drawer/responsive.drawer.component';
import HomePage from './pages/homepage/homepage.component';
import Main from './components/main/main.component'
import PageNotFound from './pages/page-not-found/page.not.found';
import Stats from './pages/stats/stats.component';
import Ranking from './pages/ranking/ranking.component';
import About from './pages/about/about.component';
import Privacy from './pages/privacy/privacy.component'
import Term from './pages/term/term.component';

class App extends React.Component {
  constructor() {
    super();

    this.state = {
      currentUser: null,
    };
  }

  componentDidMount() {
    
  }

  componentWillUnmount() {
   
  }
  render() {
    
    return (
      <div>
            <ResponsiveDrawer />
              <Switch>
              {/* <Route
                exact
                path="/lists"
                render={
                  () => <HomePage />
                  //this.state.currentUser ? <HomePage /> : <HomePage />
                }
              /> */}
              <Route exact path="/" component={HomePage} />
              <Route exact path="/lists" component={Main} />
              <Route exact path="/stats" component={Stats} />
              <Route exact path="/ranking" component={Ranking} />
              <Route exact path="/about" component={About} />
              <Route exact path="/term" component={Term} />
              <Route exact path="/privacy" component={Privacy} />

              <Route component={PageNotFound} />
              </Switch>
 
      </div>
    );
  }
}

export default App;

