import React, { useState, useEffect, useCallback } from 'react';
import Gallery from 'react-photo-gallery';
import { debounce } from '../../helpers/helpers';
import SelectedImage from './selectedImage.component';
import PropTypes from 'prop-types';

function DynamicLoading({ photos, type }) {
  const imageRenderer = useCallback(
    ({ index, left, top, key, photo }) => (
      <SelectedImage
        selected={false}
        key={key}
        margin={'2px'}
        index={index}
        photo={photo}
        left={left}
        top={top}
        direction={'column'}
        type={type}
      />
    ),
    []
  );

  const [images, setImages] = useState(photos.slice(0, 17));
  const [pageNum, setPageNum] = useState(1);
  const [loadedAll, setLoadedAll] = useState(false);

  const TOTAL_PAGES = 50;
  const loadMorePhotos = debounce(() => {
    if (pageNum > TOTAL_PAGES) {
      setLoadedAll(true);
      return;
    }

    setImages(images.concat(photos.slice(images.length, images.length + 17)));
    setPageNum(pageNum + 1);
  }, 200);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  });

  useEffect(() => {
    setImages(photos.slice(0, images.length));
  }, [photos]);

  const handleScroll = () => {
    let scrollY =
      window.scrollY ||
      window.pageYOffset ||
      document.documentElement.scrollTop;
    if (window.innerHeight + scrollY >= document.body.offsetHeight - 50) {
      loadMorePhotos();
    }
  };
  
  return (
      <div>
      {images.length > 0 && type === 'book' && (
        <Gallery
          photos={images}
          direction={'column'}
          columns={4}
          margin={3}
          renderImage={imageRenderer}
        />
      )}
      {images.length > 0 && type === 'topBook' && (
        <Gallery
          photos={images}
          direction={'column'}
          columns={5}
          margin={3}
          renderImage={imageRenderer}
        />
      )}
    </div>
  );
}
DynamicLoading.propTypes = {
  photos: PropTypes.array,
  type: PropTypes.string,
};
export default DynamicLoading;
