import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { goAmazon } from '../../helpers/helpers';
import { goRakuten } from '../../helpers/helpers';
//MUI
import Link from '@material-ui/core/Link';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Avatar from '@material-ui/core/Avatar';
import { makeStyles } from '@material-ui/core/styles';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Badge from '@material-ui/core/Badge';
import { withStyles } from '@material-ui/core/styles';
import PersonIcon from '@material-ui/icons/Person';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Grid from '@material-ui/core/Grid';
import { FaAmazon } from "react-icons/fa";

const useStyles = makeStyles((theme) => ({
    listIcon: {
        minWidth: theme.spacing(0),
      },
      listAvatar: {
        minWidth: theme.spacing(6),
      },
      mobileGrid:{
        [theme.breakpoints.down('sm')]: {
          //marginRight: theme.spacing(1),
          marginTop: theme.spacing(1),
        },
      },
      root: {
        display: 'flex',
        border: 'none',
        boxShadow: 'none',
        borderRadius: 0,
      },
      listItemText: {
        [theme.breakpoints.down('sm')]: {
          marginRight: theme.spacing(1),
        },
        color:'#000'
      },
      listButton: {
        color: '#d4d4d4',
        margin: theme.spacing(0, 0, 0, 'auto'),
      },
      avatar: {
        width: theme.spacing(4),
        height: theme.spacing(6),
    },
    amazonButton:{
        textTransform: 'none',
        [theme.breakpoints.up('sm')]: {
          maxWidth: '200px', 
          minWidth: '200px',
          //margin: theme.spacing(2),
        },
        //backgroundColor: '#FF9900',
        //borderColor: '#FF9900',
        //color:'white',
        margin: theme.spacing(0),
      },
      rakutenButton:{
        textTransform: 'none',
        [theme.breakpoints.up('sm')]: {
          maxWidth: '200px', 
          minWidth: '200px',
          margin: theme.spacing(2),
        },
        //backgroundColor: '#bf0000',
        //color:'white',
        margin: theme.spacing(1),
      },
      count:{
        marginLeft: theme.spacing(20),
        [theme.breakpoints.down('sm')]: {
          //marginRight: theme.spacing(1),
          marginLeft: theme.spacing(0),
        },
      },
      details: {
        display: 'flex',
        flexDirection: 'column',
      },
      content: {
        flex: '1 0 auto',
        maxWidth: 300,
        [theme.breakpoints.down('sm')]: {
          maxWidth: 230, //160,
        },
        paddingTop: theme.spacing(1),
        '&:last-child': {
          paddingBottom: 0,
        },
      },
      cover: {
        minWidth: 118,
        minHeight: 178,
      },
      mobilePaper: {
        fontSize: 18,
        [theme.breakpoints.down('sm')]: {
          fontSize: 14,
        },
      },
      mobilePaperAuthor: {
        fontSize: '0.8rem',
      },
      logo: {
        height: 50,
        width: 60,
      },
      categoryContent: {
        flex: '1 0 auto',
        [theme.breakpoints.down('sm')]: {
          padding: theme.spacing(1, 0, 1, 0),
        },
      },
      button: {
        margin: theme.spacing('auto', 1, 1, 'auto'),
      },
      dialogContent: {
        [theme.breakpoints.down('sm')]: {
          padding: theme.spacing(1),
        },
      },
      right: {
        justifyContent: 'flex-end',
        display: 'flex',
        padding: theme.spacing(1),
      },
      mobileButton: {
        margin: theme.spacing(0, 0, 0, 1),
      },
      paddingTitle: {
        padding: theme.spacing(2),
      },
      paddingZero: {
        padding: theme.spacing(0),
      },
      divider: {
        margin: theme.spacing(1.5, 0),
      },
      author: {
        fontSize: '1rem',
      },
      publisherName: {
        display: 'inline',
        fontSize: '0.7rem',
      },
      textLink: {
        textTransform: 'none',
        color: '#FF9900',
        marginTop: theme.spacing(1),
      },
      personIcon:{
        //marginTop: theme.spacing(1),
      }
}));

export const IndividualListItem = ({
  list,
  index,

}) => {
  const classes = useStyles();
 const [openDialog, setOpenDialog] = useState(false);
 const theme = useTheme();
 const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
 const goAuthor = (event, name) => {
  event.preventDefault();
  window.location.href = 
    `/lists?type_id=1&keyword=${name}`
    ;
}
 const handleClose = () => {
     setOpenDialog(false)
 }
  const StyledBadge = withStyles((theme) => ({
    badge: {
      left: '-1px',
      border: `1px solid ${theme.palette.background.paper}`,
      //padding: '0 4px',
    },
  }))(Badge);

  return (
    <div>
       <ListItem 
        ContainerComponent="div" 
        key={index}
        button
        //onClick = {() => imageRenderer}
        onClick = {() => setOpenDialog(true)}
        >
        <ListItemAvatar className={classes.listAvatar}>
            {index < 3 ?
                <StyledBadge 
                    anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                badgeContent={index + 1} 
                color="secondary">
                <Avatar
                    variant="square"
                    className={classes.avatar}
                    alt={`Avatar`}
                    src={list.src}
                />
                </StyledBadge>
                : 
                <Avatar
                    variant="square"
                    className={classes.avatar}
                    alt={`Avatar`}
                    src={list.src}
                />}
            
        </ListItemAvatar>
        <ListItemText 
            className={classes.listItemText} primary={`${list.title} / ${list.author}`} />
        <ListItemSecondaryAction
            className={classes.listButton}>          
          {list.count} 票
        </ListItemSecondaryAction>
    </ListItem>
    <Dialog
        //disableBackdropClick
        disableEscapeKeyDown
        open={openDialog}
        onClose={handleClose}
        //fullScreen={fullScreen}
        fullWidth={true}
        maxWidth="sm"
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title" disableTypography={true}>
          {list.title}
        </DialogTitle>

        <Divider />
        <DialogContent className={classes.dialogContent}>
          <Card className={classes.root}>
            <CardMedia
              className={classes.cover}
              image={list.src}
              title="cover image"
            />

            <div className={classes.details}>
              <CardContent className={classes.content}>
                <Typography
                  className={classes.mobilePaper}
                  component="h5"
                  variant="h5"
                >
                  {list.title}
                </Typography>

                <Typography
                  className={classes.mobilePaperAuthor}
                  variant="h6"
                  color="textSecondary"
                >
                  {list.author.includes("/") ?
                  <>
                  {list.author.split("/")
                  .filter(x => x)
                  .map((x, index) => {
                    return <Link key={index}
                    //className={classes.linkSpace}
                      onClick={(event) => {
                        goAuthor(event, x)
                      }}
                      >
                        {`${x} `}
                      </Link>
                  })}
                  </>
                  :
                  <>
                  <Link
                  onClick={(event) => {
                    goAuthor(event, list.author)
                  }}

                  >
                    {list.author}
                  </Link>
                  </>}
                </Typography>
                <Typography
                  variant="caption"
                  color="textSecondary"
                  className={classes.publisherName}
                >
                  {list.publishername}
                </Typography>
              
              </CardContent>
            </div>
          </Card>

          <Card className={classes.root}>
            <CardContent className={classes.categoryContent}>
            <Divider />
            {!isMobile && 
            (<Grid
              container
              direction="row"
              justifyContent="space-around"
              alignItems="center"
              >
             <Button
              onClick={() => goAmazon(list.isbn13)}
              variant="outlined"
              className={classes.amazonButton}
              startIcon={<FaAmazon />}
            >
              Amazon で探す
            </Button>
            <Button
              onClick={() => goRakuten(list.affiliateurl)}
              variant="outlined"
              className={classes.rakutenButton}
            >
              楽天市場で探す
            </Button>
            <PersonIcon color={'primary'}/>
            {list.count}
            </Grid>)}
            {isMobile && (
              <>
              <Grid
                  container
                  direction="row"
                  justifyContent="space-around"
                  alignItems="center"
                  className={classes.mobileGrid}
                  >
                <Button
                  onClick={() => goAmazon(list.isbn13)}
                  variant="outlined"
                  className={classes.amazonButton}
                  startIcon={<FaAmazon />}
                >
                  Amazon
                </Button>
               
                <Button
                  onClick={() => goRakuten(list.affiliateurl)}
                  variant="outlined"
                  className={classes.rakutenButton}
                >
                  楽天市場
                </Button>
                <PersonIcon color={'primary'} className={classes.count}/>
                    <div>{list.count}</div>
                </Grid>

            </>)}

            </CardContent>
          </Card>

            <div className={classes.right}>
              <Button
                //className={classes.mobileButton}
                variant="outlined"
                color="default"
                onClick={() => setOpenDialog(false)}
              >
                閉じる
              </Button>
             
            </div>
          
        </DialogContent>
        
      </Dialog>
    </div>
  );
};
IndividualListItem.propTypes = {
  list: PropTypes.object,
  index: PropTypes.number,
};
export default IndividualListItem;
