import React, { useEffect, useState, useCallback, useRef } from 'react';
import SelectedImage from '../../components/main/selectedImage.component';
import { firestore } from '../../firebase/firebase.utils';

import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut, getElementsAtEvent } from 'react-chartjs-2';


import Gallery from 'react-photo-gallery';
//MUI
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
//icon

ChartJS.register(ArcElement, Tooltip, Legend);
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    margin: theme.spacing(2, 'auto'),
    padding: theme.spacing(0),
    [theme.breakpoints.up('sm')]: {
      width: 650,
    },
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  title: {
    margin: theme.spacing(2, 0, 0, 0),
    [theme.breakpoints.down('sm')]: {
      margin: theme.spacing(0),
    },
  },

  paper: {
    margin: theme.spacing(1.5, 0),
    padding: theme.spacing(1, 0),
    [theme.breakpoints.down('sm')]: {
      margin: theme.spacing(0.5, 0),
    },
  },
  imgPaper: {
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(0, 0, 3, 0),
    },
    padding: theme.spacing(3),
  },
  cardPaper: {
    margin: theme.spacing(1, 0),
  },
  year: {
    [theme.breakpoints.down('sm')]: {
      margin: theme.spacing(0),
    },
    margin: theme.spacing(1, 0, 0,0),
  },
}));

export const Ranking = () => {
  const classes = useStyles();
  const theme = useTheme();

  const [items, setItems] = useState({ datasets: [], labels: [] });
  const { labels } = items;
  const [show, setShow] = useState(true);
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const pad = isMobile ? 5 : 10;
  //const [authorPrice, setAuthorPrice] = useState(0);
  const [clickedType, setClickedType] = useState('');
  const [worksImg, setWorksImg] = useState([]);
  const chartRef = useRef();
  const onClick = (event) => {
    //console.log(getElementsAtEvent(chartRef.current, event));
    //console.log(event)
    //event[0] ? setClickedType(labels[e[0]._index]) : undefined
    const elements = getElementsAtEvent(chartRef.current, event);
    if (!elements.length) return;
    setClickedType(labels[elements[0].index])

  }
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    
    plugins: {
      legend: {
        display: show,
        position: 'top',
        labels: {
          boxWidth: 13,
          padding: pad,
        },
      },
    },
  };
  // const options = {
  //   legend: {
  //     display: show,
  //     position: 'top',
  //     labels: {
  //       boxWidth: 13,
  //       padding: pad,
  //     },
  //   },
  // };
  const backgroundColor = [
    'rgba(255, 99, 132, 0.2)',
    'rgba(54, 162, 235, 0.2)',
    'rgba(255, 206, 86, 0.2)',
    'rgba(75, 192, 192, 0.2)',
    'rgba(153, 102, 255, 0.2)',
    'rgba(255, 159, 64, 0.2)',
    'rgba(173, 216, 230, 0.2)',
    'rgba(255, 99, 132, 0.2)',
    'rgba(54, 162, 235, 0.2)',
    'rgba(255, 206, 86, 0.2)',
  ];
  const borderColor = [
    'rgba(255, 99, 132, 1)',
    'rgba(54, 162, 235, 1)',
    'rgba(255, 206, 86, 1)',
    'rgba(75, 192, 192, 1)',
    'rgba(153, 102, 255, 1)',
    'rgba(255, 159, 64, 1)',
    'rgba(173, 216, 230, 1)',
    'rgba(255, 99, 132, 1)',
    'rgba(54, 162, 235, 1)',
    'rgba(255, 206, 86, 1)',
  ];
 
  const imageRenderer = useCallback(
    ({ index, left, top, key, photo }) => (
      <SelectedImage
        selected={false}
        key={key}
        margin={'2px'}
        index={index}
        photo={photo}
        left={left}
        top={top}
        direction={'column'}
        page={'main'}
      />
    ),
    []
  );
  useEffect(() => {
      firestore
        .collection('author-ranking')
        .orderBy('count', 'desc')
        // .collection('books')
        // .orderBy('author')
        .get()
        .then((snapshot) => {
          // let booksArray = [];
          // let totalDataArray = [];
          // snapshot.forEach((doc) => {
          //   let authorName = doc.data().author;
          //   if (authorName.includes('/')) {
          //     authorName = authorName.split('/')[0];
          //     //booksArray = booksArray.concat(newName);
          //   }
          //   booksArray.push(authorName);
          // });

          // let counter = {};

          // booksArray.forEach(function (obj) {
          //   var key = JSON.stringify(obj);

          //   counter[key] = (counter[key] || 0) + 1;
          // });

          // const pickHighest = (obj, num = 1) => {
          //   const requiredObj = {};
          //   if (num > Object.keys(obj).length) {
          //     return obj;
          //   }
          //   Object.keys(obj)
          //     .sort((a, b) => obj[b] - obj[a])
          //     .forEach((key, ind) => {
          //       if (ind < num) {
          //         requiredObj[key] = obj[key];
          //       }
          //     });
          //   return requiredObj;
          // };
          // const sorted = pickHighest(counter, 10);

          // var keys = [];
          // for (var key in sorted) {
          //   keys.push(key.replaceAll('"', ''));
          // }
          
          // totalDataArray.push({
          //   data: Object.values(sorted),
          //   backgroundColor: backgroundColor,
          //   borderColor: borderColor,
          //   borderWidth: 1,
          // });
          // setItems({
          //   labels: keys,
          //   datasets: totalDataArray,
          // });
          
          let totalDataArray = [];
          let data = [];
          let keys = [];
          snapshot.forEach((doc) => {
            data.push(doc.data().count)
            keys.push(doc.data().name.replaceAll('"', ''))
          });
         
          totalDataArray.push({
            data: data,
            backgroundColor: backgroundColor,
            borderColor: borderColor,
            borderWidth: 1,
          });
          setItems({
            labels: keys,
            datasets: totalDataArray,
          });
        });

  }, []);

  useEffect(() => {
    if (clickedType) {
      firestore
        .collection('books')
        .where('author', '>=', clickedType)
        .where('author', '<=', clickedType + '\uf8ff')
        .get()
        .then((snapshot) => {
          //let price = 0;
          let allLists = [];
          snapshot.forEach((doc) => {
            //price += doc.data().price;
            allLists.push({
              width: 4,
              height: 6,
              affiliateurl: doc.data().affiliateurl,
              src: doc.data().src,
              title: doc.data().title,
              isbn13: doc.data().isbn13,
              count: doc.data().count,
              publishername: doc.data().publishername,
              author: doc.data().author
            });
          });
          
          allLists.sort((a,b) => (a.title > b.title) ? 1 : ((b.title > a.title) ? -1 : 0))
          
          setWorksImg(allLists);

        });
    }
  }, [clickedType]);


  return (
    <div className={classes.root}>
      <div className="chart">
        <Grid container direction="row" justifyContent="center" alignItems="center">
          <Typography
            className={classes.title}
            variant={isMobile ? 'h6' : 'h5'}
            gutterBottom
          >
            {"著者グラフ - 上位10名"}
          </Typography>
        </Grid>
        <Paper className={classes.paper}>
          <Doughnut
            width={300}
            height={300}
            data={items}
            options={options}
            ref={chartRef}
            onClick={onClick}
            // getElementAtEvent={(e) =>
            //   e[0] ? setClickedType(labels[e[0]._index]) : undefined
            // }
          />
        </Paper>
      </div>
      <Grid container justifyContent="flex-end">
        <FormControlLabel
          control={
            <Switch
              checked={show}
              onChange={(event) => {
                setShow(event.target.checked);
              }}
              name="label-show"
              color="primary"
            />
          }
          label="ラベル表示"
        />
      </Grid>
      
      <Paper className={classes.cardPaper}>
      <Grid container direction="row" justifyContent="center" alignItems="center">
          {worksImg.length > 0 && (
            <>
              
              <Typography className={classes.year} variant="h6" gutterBottom>
                {clickedType}
              </Typography>
              
            </>
          )}
        </Grid>
        {worksImg.length > 0 && (
          <div className={classes.imgPaper}>
            <Gallery
              photos={worksImg}
              direction={'column'}
              columns={4}
              margin={3}
              renderImage={imageRenderer}
            />
          </div>
        )}
      </Paper>

    </div>
  );
};

export default Ranking;
