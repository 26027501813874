import ISBN from 'isbn3';
export const goAmazon = (apiId) => {
  let isbn10 = ISBN.asIsbn10(apiId)
  if (apiId) {
      let itemurl = `https://www.amazon.co.jp/dp/${isbn10}/ref=nosim?tag=${process.env.REACT_APP_AMAZON_ASSOCIATE_ID}`;
      window.open(itemurl, '_blank');
    }

};
export const goRakuten = (affiliateurl) => {
  if (affiliateurl) {
    window.open(affiliateurl, '_blank');
  } 
};
export const debounce = (func, wait, immediate) => {
  let timeout;
  return function() {
    const context = this,
      args = arguments;
    let later = function() {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    const callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
}