import React from 'react';

export const PageNotFound = () => {
  return (
    <h1 style={{ margin: '50px' }} data-testid="page-not-found">
      404 Page not found
    </h1>
  );
};

export default PageNotFound;
