import React from 'react';
//MUI
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > * + *': {
      marginTop: theme.spacing(2),
      padding: '0 30px',
    },
    margin: theme.spacing(1),
    float: 'center',
  },
  margin: {
    margin: theme.spacing(5, 0, 0, 1),
  },
  marginTop: {
    margin: theme.spacing(2, 0, 0, 0),
  },
}));
export const Term = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Typography className={classes.margin} variant="h4" gutterBottom>
        利用規約
      </Typography>
      <Typography>
        この利用規約（以下、「本規約」といいます。）は「#名刺代わりの小説10選集計ツール」運営（以下、「当事務局」といいます。）がこのウェブサイト上で提供するサービス（以下、「本サービス」といいます。）の利用条件を定めるものです。利用者の皆さま（以下、「ユーザー」といいます。）には，本規約に従って本サービスをご利用いただきます。
      </Typography>
      <Typography
        //className={classes.marginTop}
        variant="h6"
        gutterBottom
      >
        第1条（適用）
      </Typography>
      <Typography variant="body1" gutterBottom>
        1.
        本規約はユーザーと当事務局との間の本サービスの利用に関わる一切の関係に適用されるものとします。
        2.
        当事務局は本サービスに関し本規約のほかご利用にあたってのルール等各種の定め（以下、「個別規定」といいます。）をすることがあります。これら個別規定はその名称のいかんに関わらず本規約の一部を構成するものとします。
        3.
        本規約の規定が前条の個別規定の規定と矛盾する場合には個別規定において特段の定めなき限り個別規定の規定が優先されるものとします。
      </Typography>
      <Typography variant="h6" gutterBottom>
        第2条（利用登録）
      </Typography>
      <Typography variant="body1" gutterBottom>
        本サービスにおいては登録希望者が本規約に同意の上、当事務局の定める方法によって利用登録するものとします。
      </Typography>
      <Typography variant="h6" gutterBottom>
        第3条（利用料金および支払方法）
      </Typography>
      <Typography variant="body1" gutterBottom>
        原則2021年11月1日時点では無料でサービスを公開していますが、有料となる機能を追加する場合があります。
      </Typography>
      <Typography variant="h6" gutterBottom>
        第4条（禁止事項）
      </Typography>
      <Typography variant="body1" gutterBottom>
        ユーザは、本サービスの利用にあたり、以下の行為をしてはならないものとします。
        1. 法令または公序良俗に違反する行為 2. 犯罪行為に関連する行為 3.
        本サービスの内容等、本サービスに含まれる著作権，商標権ほか知的財産権を侵害する行為
        4. 当事務局、ほかのユーザー、またはその他第三者のサーバーまたはネットワークの機能を破壊したり妨害したりする行為
        5. 当事務局のサービスの運営を妨害するおそれのある行為 
        6. 不正アクセスをしまたはこれを試みる行為 
        7. 他のユーザーに関する個人情報等を収集または蓄積する行為 
        8. 不正な目的を持って本サービスを利用する行為 
        9. 本サービスの他のユーザーまたはその他の第三者に不利益、損害、不快感を与える行為
        10. 他のユーザーに成りすます行為 
        11. 当事務局のサービスに関連して反社会的勢力に対して直接または間接に利益を供与する行為
        12. その他、当事務局が不適切と判断する行為
      </Typography>
      <Typography variant="h6" gutterBottom>
        第5条（本サービスの提供の停止等）
      </Typography>
      <Typography variant="body1" gutterBottom>
        当事務局は以下のいずれかの事由があると判断した場合ユーザーに事前に通知することなく本サービスの全部または一部の提供を停止または中断することができるものとします。
        1. 本サービスにかかるコンピュータシステムの保守点検または更新を行う場合
        2.
        地震、落雷、火災、停電または天災などの不可抗力により，本サービスの提供が困難となった場合
        3. コンピュータまたは通信回線等が事故により停止した場合 4.
        その他，当事務局が本サービスの提供が困難と判断した場合
        当事務局は本サービスの提供の停止または中断によりユーザーまたは第三者が被ったいかなる不利益または損害についても一切の責任を負わないものとします。
      </Typography>
      <Typography variant="h6" gutterBottom>
        第6条（利用制限および登録抹消）
      </Typography>
      <Typography variant="body1" gutterBottom>
        当事務局はユーザーが以下のいずれかに該当する場合には事前の通知なくユーザーに対して本サービスの全部もしくは一部の利用を制限しまたはユーザーとしての登録を抹消することができるものとします。
        1. 本規約のいずれかの条項に違反した場合 2.
        登録事項に虚偽の事実があることが判明した場合 3.
        料金等の支払債務の不履行があった場合 4.
        当事務局からの連絡に対し15日以上返答がない場合 5.
        本サービスについて最終の利用から一定期間利用がない場合 6.
        その他当事務局が本サービスの利用を適当でないと判断した場合
        当事務局は本条に基づき当事務局が行った行為によりユーザーに生じた損害について，一切の責任を負いません。
      </Typography>
      <Typography variant="h6" gutterBottom>
        第8条（保証の否認および免責事項）
      </Typography>
      <Typography variant="body1" gutterBottom>
        1.
        当事務局は本サービスに事実上または法律上の瑕疵（安全性、信頼性、正確性、完全性、有効性、特定の目的への適合性、セキュリティなどに関する欠陥、エラーやバグ、権利侵害などを含みます。）がないことを明示的にも黙示的にも保証しておりません。
        2.
        当事務局は本サービスに起因してユーザーに生じたあらゆる損害について一切の責任を負いません。ただし本サービスに関する当事務局とユーザーとの間の契約（本規約を含みます）が消費者契約法に定める消費者契約となる場合この免責規定は適用されません。
        3.
        前項ただし書に定める場合であっても当事務局は当事務局の過失（重過失を除きます）による債務不履行または不法行為によりユーザーに生じた損害のうち特別な事情から生じた損害（当事務局またはユーザーが損害発生につき予見しまたは予見し得た場合を含みます）について一切の責任を負いません。また当事務局の過失（重過失を除きます）による債務不履行または不法行為によりユーザーに生じた損害の賠償はユーザーから当該損害が発生した月に受領した利用料の額を上限とします。
        4.
        当事務局は本サービスに関してユーザーと他のユーザーまたは第三者との間において生じた取引、連絡または紛争等について一切責任を負いません。
      </Typography>
      <Typography variant="h6" gutterBottom>
        第9条（サービス内容の変更等）
      </Typography>
      <Typography variant="body1" gutterBottom>
        当事務局はユーザーに通知することなく本サービスの内容を変更しまたは本サービスの提供を中止することができるものとしこれによってユーザーに生じた損害について一切の責任を負いません。
      </Typography>
      <Typography variant="h6" gutterBottom>
        第10条（利用規約の変更）
      </Typography>
      <Typography variant="body1" gutterBottom>
        当事務局は必要と判断した場合にはユーザーに通知することなくいつでも本規約を変更することができるものとします。なお本規約の変更後本サービスの利用を開始した場合には当該ユーザーは変更後の規約に同意したものとみなします。
      </Typography>
      <Typography variant="h6" gutterBottom>
        第11条（個人情報の取扱い）
      </Typography>
      <Typography variant="body1" gutterBottom>
        当事務局は本サービスの利用によって取得する個人情報については当事務局「プライバシーポリシー」に従い適切に取り扱うものとします。
      </Typography>
      <Typography variant="h6" gutterBottom>
        第12条（通知または連絡）
      </Typography>
      <Typography variant="body1" gutterBottom>
        ユーザーと当事務局との間の通知または連絡は当事務局の定める方法によって行うものとします。当事務局はユーザーから当事務局が別途定める方式に従った変更届け出がない限り現在登録されている連絡先が有効なものとみなして当該連絡先へ通知または連絡を行い、これらは発信時にユーザーへ到達したものとみなします。
      </Typography>
      <Typography variant="h6" gutterBottom>
        第13条（権利義務の譲渡の禁止）
      </Typography>
      <Typography variant="body1" gutterBottom>
        ユーザーは当事務局の書面による事前の承諾なく利用契約上の地位または本規約に基づく権利もしくは義務を第三者に譲渡し、または担保に供することはできません。
      </Typography>
      <Typography variant="h6" gutterBottom>
        第14条（準拠法・裁判管轄）
      </Typography>
      <Typography>
        本規約の解釈にあたっては日本法を準拠法とします。
        本サービスに関して紛争が生じた場合には当事務局の本店所在地を管轄する裁判所を専属的合意管轄とします。
      </Typography>
    </div>
  );
};

export default Term;
