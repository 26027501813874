import firebase from 'firebase/app'
import 'firebase/firestore';
import 'firebase/auth';
import 'firebase/analytics';

const config = {
    apiKey: "AIzaSyDTRLsRLbA01zBhpzveWX8mcgx7GwGv2hU",
    authDomain: "top-ten-novels.firebaseapp.com",
    projectId: "top-ten-novels",
    storageBucket: "top-ten-novels.appspot.com",
    messagingSenderId: "989444620892",
    appId: "1:989444620892:web:c4ed6f4e7d5c53eadbdc99",
    measurementId: "G-RJCKZ2D03N"
};

firebase.initializeApp(config);
firebase.analytics();
export const auth = firebase.auth();
export const firestore = firebase.firestore();

//export const signInWithGoogle = () => auth.signInWithPopup(provider);

export default firebase;
