import React from 'react';
import Link from '@material-ui/core/Link';
//import { ReactComponent as Logo } from '../../assets/tmdb-2020.svg';
import { firestore } from '../../firebase/firebase.utils';
import './about.styles.scss';


export const About = () => {
  
  // const perseData = () => {
  //   const demodata = "#名刺代わりの小説10選\n#読書好きさんと繋がりたい\n存在の耐えられない軽さ/ミランクンデラ\n戦う操縦士/サンテグジュペリ\nマノンレスコー /プレヴォ\nシェリ/コレット\n銀の匙/中勘助\n熱帯安楽椅子/山田詠美\n泥流地帯/三浦綾子\n銀河英雄伝説/田中芳樹\nブラックスワン/ユン・チアン\nアシェンデン/モーム"
  //   const demodata2 = "#名刺代わりの小説10選\n#読書好きさんと繋がりたい\n銀河英雄伝説/田中芳樹\n銀河英雄伝説/田中芳樹\n銀河英雄伝説/田中芳樹\n銀河英雄伝説/田中芳樹\n銀河英雄伝説/田中芳樹\n銀河英雄伝説/田中芳樹\n銀河英雄伝説/田中芳樹\n銀河英雄伝説/田中芳樹\n銀河英雄伝説/田中芳樹\n銀河英雄伝説/田中芳樹"
  //   const booksList = []
     
  //   let separater = ""
  //   let parameters = []
  //   const allTweets = [demodata, demodata2]
  //   console.log(demodata)
  //   console.log(demodata2)
  //   allTweets.map((tweet, index) => {
  //     separater = "default"
  //     let allLines = tweet.split(/\r\n|\n/);
  //     let validTweet = [];
      
  //     allLines = allLines.filter((line) => line.length !== 0) 
  //     allLines
  //     .filter((line) => line.charAt(0) !== '#')
  //     .map((line, index) => {
  //       parameters = []
        
  //       if(index === 0){
  //         if(line.includes("/")){
  //           separater = "/"
  //         }else if(line.includes("／")){
  //           separater = "／"
  //         }else if(line.includes("『")){
  //           separater = "『"
  //         }else if(line.includes("「")){
  //           separater = "「"
  //         }else if(line.includes(" ")){
  //           separater = " "
  //         }else if(line.includes("　")){
  //           separater = "　"
  //         }else{
            
  //         }
  //       }
        
  //       if (separater ===  "default"){
  //           return null;
  //       }

  //       if(line.includes(separater)){
  //         if(separater === "『"){
           
  //           if(line.charAt(0) === "『"){
  //             line = line.replace("『", '')
  //             parameters = line.split("』").filter(x => x)
  //           }else{
  //             line = line.replace("』", '')
  //             parameters = line.split("『").filter(x => x)
  //           }
           
  //         }else if(separater === "「"){
  //           if(line.charAt(0) === "「"){
              
  //             line = line.replace("「", '')
  //             parameters = line.split("」").filter(x => x)
              
  //           }else{
  //             line = line.replace("」", '')
  //             parameters = line.split("「").filter(x => x)
  //           }
  //         }else{
  //           parameters = line.split(separater).filter(x => x)
  //         }
          
  //         if(parameters.length === 2){
  //           validTweet.push({
  //             value1: parameters[0],
  //             value2: parameters[1]
  //           })
  //         }
  //        console.log("valid", validTweet)
  //       }
  //       return null
  //     })
      
  //     if(validTweet.length <= 10){
  //       let uniqueArray = a => [...new Set(a.map(o => JSON.stringify(o)))].map(s => JSON.parse(s));
  //       let uni = uniqueArray(validTweet)
        
  //       uni.forEach((item, index) => {
         
  //         booksList.push(item)
          
  //       })
  //     }else{
        
  //     }
  //     console.log('book', booksList)
  //     return null
  //   })
  // }
  
  // perseData()

  return (
    <div className="about">
    
    <h2 className="title">書籍データ</h2>
      <div className="content">
      <span>
        本アプリケーションで使用されている、書名、著者名、出版社名、書籍画像などの書籍関連のメタデータは、すべて
        <Link href="https://webservice.rakuten.co.jp/api/bookstotalsearch/">
          Rakuten Books Total Search API
        </Link>
        のものを使用しています。{' '}
      </span>
      <span>
        このアプリは楽天ブックスの総合検索APIを使用していますが、Rakuen
        Developers が推奨・認定しているものではありません。
      </span>
      <p>
        All book-related metadata used in this application, including book
        title, author name, publisher name and book image is supplied by{' '}
        <Link href="https://webservice.rakuten.co.jp/api/bookstotalsearch/">
          Rakuten Books Total Search API
        </Link>
      </p>
      <span>
        This app uses the Rakuten Books Total Search API but is not endorsed or
        certified by Rakuten Books.
      </span>
      </div>
      <div className="img-container">
        <a
          href="https://webservice.rakuten.co.jp/"
          rel="noreferrer"
          target="_blank"
        >
          <img
            src="https://webservice.rakuten.co.jp/img/credit/200709/credit_31130.gif"
            border="0"
            alt="Rakuten Web Service Center"
            title="Rakuten Web Service Center"
            width="311"
            height="30"
          />
        </a>
      </div>

    </div>
  );
};

export default About;
